import ImagedBackground from "components/ImagedBackground/ImagedBackground";
import DesktopSafeArea from "components/legacy/DesktopSafeArea";
import PaginationLayout from "components/PaginationLayout/PaginationLayout";
import PostItem from "components/PostsList/PostItem/PostItem";
import {
  PostsListContainer,
  PostsListPostsContainer,
} from "components/PostsList/PostsList.components";
import { Entry } from "contentful";
import { useRouter } from "next/router";
import React, { FunctionComponent } from "react";
import { IPostFields } from "types/generated/contentful";

type PostsListProps = {
  posts: Entry<IPostFields>[];
  page: number;
};

const PostsList: FunctionComponent<PostsListProps> = ({ posts, page }) => {
  const router = useRouter();
  return (
    <>
      <ImagedBackground>
        <PaginationLayout paginationKey={`${router.asPath}`}>
          <PostsListPostsContainer id="posts">
            <DesktopSafeArea>
              <PostsListContainer>
                {posts.map((post) => (
                  <PostItem post={post} key={post.sys.id} />
                ))}
              </PostsListContainer>
            </DesktopSafeArea>
          </PostsListPostsContainer>
        </PaginationLayout>
      </ImagedBackground>
    </>
  );
};

export default PostsList;
