import {
  PaginationContainer,
  PaginationItem,
  PaginationPagesContainer,
  PaginationPagesSpan,
} from "components/Pagination/Pagination.components";
import Image from "next/image";
import Link from "next/link";
import React, { FunctionComponent } from "react";
import { getTag } from "utils/tagUtils";
import IconArrowLeft from "./icon_arrow_left.webp";
import IconArrowRight from "./icon_arrow_right.webp";

type PaginationProps = {
  pages: number;
  current: number;
  tag?: string;
};

const getPagePath = (page: number) => (page > 1 ? `/${page}` : "/");

const getPageUrl = (page: number, tag?: string) =>
  `${tag ? `/tag/${getTag(tag)}${getPagePath(page)}` : getPagePath(page)}#tags`;

const getPages = (current: number, total: number) => {
  if (current === 1) {
    return Array.from(Array(Math.min(total, 3)).keys()).map((p) => p + 1);
  } else if (current >= total) {
    return Array.from(Array(Math.min(total, 3)).keys())
      .map((p) => total - p)
      .reverse();
  } else {
    const pages = [current - 1, current];
    return total < 3 ? pages : [...pages, current + 1];
  }
};

const Pagination: FunctionComponent<PaginationProps> = ({
  pages,
  current,
  tag,
}) => {
  return (
    <PaginationContainer>
      <PaginationPagesContainer>
        <Link href={getPageUrl(current - 1, tag)} passHref scroll={false}>
          <PaginationItem
            addjustMargin="left"
            isCurrent={false}
            isSmall
            tabIndex={current === 1 ? -1 : 0}
            isDisabled={current === 1}
          >
            <Image
              alt="Previous page"
              src={IconArrowLeft}
              width={8.32}
              layout="fixed"
              height={12.8}
              objectFit="cover"
            />
          </PaginationItem>
        </Link>
        {getPages(current, pages).map((page) => (
          <Link
            passHref
            href={getPageUrl(page, tag)}
            scroll={false}
            key={page.toString()}
          >
            <PaginationItem
              isDisabled={false}
              isSmall={false}
              tabIndex={current === page ? -1 : 0}
              isCurrent={page === current}
              key={page}
            >
              {page.toString()}
            </PaginationItem>
          </Link>
        ))}
        <Link href={getPageUrl(current + 1, tag)} passHref scroll={false}>
          <PaginationItem
            isCurrent={false}
            isSmall
            addjustMargin="right"
            isDisabled={current === pages}
          >
            <Image
              alt="Next page"
              src={IconArrowRight}
              layout="fixed"
              width={8.32}
              height={12.8}
              objectFit="cover"
            />
          </PaginationItem>
        </Link>
      </PaginationPagesContainer>
      <PaginationPagesSpan>Page {`${current}/${pages}`}</PaginationPagesSpan>
    </PaginationContainer>
  );
};

export default Pagination;
