import { media } from "components/legacy/styles/media";
import React, { FunctionComponent } from "react";
import styled from "styled-components";

type DesktopSafeAreaProps = {
  children?: React.ReactNode;
};

const DesktopSafeAreaContainer = styled.div`
  margin: 0px 200px;

  ${media.down("LAPTOP")`
    margin: 0 144px;
  `}

  ${media.down("TABLET")`
    margin: 0 96px;
  `}

  ${media.down("TABLET_SMALL")`
    margin: 0 80px;
  `}

  ${media.down("MOBILE_LARGE")`
    margin: 0 24px;
  `}

  ${media.down("MOBILE_SMALL")`
    margin: 0 20px;
  `}
`;

// Component used to handle fixed left/right lines in Navbar.
const DesktopSafeArea: FunctionComponent<DesktopSafeAreaProps> = ({
  children,
}) => {
  return <DesktopSafeAreaContainer>{children}</DesktopSafeAreaContainer>;
};

export default DesktopSafeArea;
