import { PaginationLayoutContainer } from "components/PaginationLayout/PaginationLayout.components";
import React, { FunctionComponent } from "react";

const slideRight = {
  name: "Slide Right",
  variants: {
    initial: {
      opacity: 0,
      left: "-100%",
      scale: 0.6,
    },
    animate: {
      opacity: 1,
      left: 0,
      scale: 1,
    },
    exit: {
      opacity: 0,
      left: "100%",
      scale: 0.6,
    },
  },
  transition: {
    duration: 0.7,
  },
};

const PaginationLayout: FunctionComponent<{
  children?: React.ReactNode;
  paginationKey: string;
}> = ({ children, paginationKey }) => {
  return (
    <PaginationLayoutContainer
      key={paginationKey}
      initial="initial"
      animate="animate"
      exit="exit"
      variants={slideRight.variants}
      transition={slideRight.transition}
    >
      {children}
    </PaginationLayoutContainer>
  );
};

export default PaginationLayout;
