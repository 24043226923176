import styled, { css } from "styled-components";
import { colors } from "styles/colors";
import { HorizontalGap } from "styles/gap";

const RedBackground = () => css`
  background: ${colors.red._500};
  box-shadow: 0px 1.8px 1.8px #e09898,
    inset 0px 2.4px 2.4px rgba(255, 219, 219, 0.6),
    inset 0px -2.4px 2.4px rgba(102, 0, 0, 0.25);
`;

const GrayBackground = () => css`
  background: ${colors.warm};
  box-shadow: 0px 1.2px 1.2px rgba(255, 255, 255, 0.45),
    inset 0px 0px 7.2px #d2bbbb;
`;

export const PaginationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PaginationPagesSpan = styled.span`
  font-family: "Barlow";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  color: ${colors.text_second};
  margin-top: 12px;
`;

export const PaginationPagesContainer = styled.div`
  display: flex;
  ${HorizontalGap(8)}
  align-items: center;
`;

export const PaginationItem = styled.a<{
  isCurrent: boolean;
  isSmall: boolean;
  isDisabled: boolean;
  addjustMargin?: "left" | "right";
}>`
  outline: none;
  box-sizing: border-box;
  font-family: "Barlow Semi Condensed";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.isSmall ? 32 : 48)}px;
  height: ${(props) => (props.isSmall ? 32 : 48)}px;
  border-radius: 24px;
  color: ${(props) => (props.isCurrent ? colors.white : colors.text_main)};
  position: relative;

  ${(props) =>
    props.addjustMargin === "left" &&
    css`
      padding-right: 3px;
    `}
  ${(props) =>
    props.addjustMargin === "right" &&
    css`
      padding-left: 3px;
    `}

  ${(props) => props.isCurrent && RedBackground()}
  ${(props) => !props.isCurrent && GrayBackground()}

  ${(props) =>
    props.isDisabled
      ? css`
          opacity: 0.4;
          pointer-events: none;
        `
      : css`
          :hover {
            ${props.isCurrent
              ? css`
                  background: ${colors.red._600};
                  box-shadow: 0px 1.8px 1.8px #e09898,
                    inset 0px 2.4px 2.4px rgba(255, 219, 219, 0.6),
                    inset 0px -2.4px 2.4px rgba(102, 0, 0, 0.25);
                `
              : css`
                  background-color: ${colors.red._80};
                  border: 1px solid ${colors.red._500};
                `}
          }
        `}


  :focus, :focus-visible {
    outline: none;
  }

  ${(props) =>
    !props.isDisabled &&
    css`
      :focus-visible {
        ::after {
          content: "";
          width: ${props.isSmall ? 24 : 32}px;
          height: 2px;
          background-color: ${colors.red._500};
          position: absolute;
          left: 0px;
          right: 0px;
          bottom: -8px;
          margin: auto;
        }
      }
    `}
`;
