import { motion } from "framer-motion";
import styled from "styled-components";
import { colors } from "styles/colors";
import {
  BlogTitleLStyle,
  BlogTitleMStyle,
  BlogTitleSStyle,
} from "styles/texts";
import { down, downPx } from "utils/media";
import BgWeb from "./bg_web.webp";
import BgMobile from "./bg_mobile.webp";

export const TopBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1190px;
  margin: 0px auto;
`;

export const TopBarHeaderContainer = styled.div`
  display: flex;
  text-align: left;
  margin-top: 64px;
  margin-bottom: 80px;
  align-items: center;

  ${down("lg")} {
    margin-bottom: 64px;
  }

  ${down("md")} {
    margin: 56px 0px;
  }

  ${down("sm")} {
    margin: 48px 0px;
  }
`;

export const TopBarHeader = styled.h2`
  ${BlogTitleLStyle()}
  margin: 0px 0px;
  padding: 0px;
  flex: 1;
  text-align: left;
  z-index: 2;

  ${down("xl")} {
    ${BlogTitleMStyle()}
  }

  ${down("md")} {
    ${BlogTitleSStyle()}
  }

  ${down("sm")} {
    width: 100%;
  }

  > b {
    color: ${colors.red._500};
    font-weight: normal;
  }
`;

export const TopBarTagsContainer = styled.div`
  border-top: 1px solid ${colors.warm_lines};
  border-bottom: 1px solid ${colors.warm_lines};
  padding: 32px 0px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;

  ${down("md")} {
    padding: 24px 0px;
    border-bottom: none;
  }
`;

export const TopBarImageContainer = styled(motion.div)`
  width: 200px;
  height: 200px;
  min-width: 200px;
  min-height: 200px;
  align-self: flex-end;

  ${down("lg")} {
    min-width: 160px;
    min-height: 160px;
    width: 160px;
    height: 160px;
  }

  ${down("md")} {
    min-width: unset;
    min-height: unset;
    width: 112px;
    height: 112px;
  }

  ${down("sm")} {
    width: 104px;
    height: 104px;
  }

  /* Hide model just below 320px. */
  ${downPx(319.9)} {
    display: none;
  }
`;

export const TopBarImageWrapper = styled.div`
  width: 100%;
  height: 100%;

  ${down("lg")} {
    transform: translateX(-32px) translateY(-16px);
  }

  ${down("md")} {
    transform: translateY(-16px) translateX(-32px);
  }

  ${down("sm")} {
    transform: translateY(-32px) translateX(-32px);
  }
`;

export const TopBarBackground = styled.div`
  width: 100%;
  bottom: 0px;
  position: absolute;
  left: 0px;
  right: 0px;
  z-index: -1;
  background-image: url(${BgWeb.src});
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;

  ${down("md")} {
    background-image: url(${BgMobile.src});
  }
`;
