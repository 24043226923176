import {
  PostItemAnimatedReadMore,
  PostItemCircleDivider,
  PostItemContainer,
  PostItemData,
  PostItemDate,
  PostItemDescription,
  PostItemImage,
  PostItemImageContainer,
  PostItemImageMain,
  PostItemLink,
  PostItemReadMoreContainer,
  PostItemTag,
  PostItemTagsDateContainer,
  PostItemTitle,
} from "components/PostsList/PostItem/PostItem.components";
import { Entry } from "contentful";
import Image from "next/image";
import React, { FunctionComponent } from "react";
import { IPostFields } from "types/generated/contentful";
import ShakeBox from "components/ShakeBox/ShakeBox";
import Link from "next/link";
import { formatPostDate } from "utils/dateHelpers";
import { getUrl } from "utils/imageHelpers";
import ReadMoreSpan from "components/ReadMoreSpan/ReadMoreSpan";

type PostItem = {
  post: Entry<IPostFields>;
};

const PostItem: FunctionComponent<PostItem> = ({ post }) => {
  const imageSize = post.fields.image.fields.file.details.image;
  return (
    <ShakeBox>
      <Link href={`/post/${post.fields.slug}`} passHref>
        <PostItemLink>
          <PostItemContainer>
            <PostItemImageMain>
              <PostItemImageContainer>
                <PostItemImage>
                  <Image
                    alt={post.fields.image.fields.description}
                    src={getUrl(post.fields.image)}
                    objectFit="cover"
                    layout="fill"
                  />
                </PostItemImage>
              </PostItemImageContainer>
            </PostItemImageMain>
            <PostItemData>
              <PostItemTitle>{post.fields.title}</PostItemTitle>
              <PostItemTagsDateContainer>
                {post.fields.tags.map((tag) => (
                  <PostItemTag key={tag}>#{tag}</PostItemTag>
                ))}
                <PostItemCircleDivider />
                <PostItemDate>
                  {formatPostDate(post.sys.createdAt)}
                </PostItemDate>
              </PostItemTagsDateContainer>
              <PostItemDescription>
                {post.fields.highlight}{" "}
                <PostItemReadMoreContainer>
                  Read&nbsp;more
                </PostItemReadMoreContainer>
                <PostItemAnimatedReadMore>
                  <ReadMoreSpan />
                </PostItemAnimatedReadMore>
              </PostItemDescription>
            </PostItemData>
          </PostItemContainer>
        </PostItemLink>
      </Link>
    </ShakeBox>
  );
};

export default PostItem;
