import styled from "styled-components";
import { colors } from "styles/colors";
import { LeadMStyle, ListTitleLStyle } from "styles/texts";
import { DefaultFocus } from "styles/utils";
import { down } from "utils/media";

export const PostItemImage = styled.div`
  border-radius: 8px;
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 200ms linear;
`;

export const PostItemImageContainer = styled.div`
  position: relative;
  max-width: 231px;
  height: 146px;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 8px;
  aspect-ratio: 273/176;

  ${down("md")} {
    max-width: 100%;
    height: unset;
  }

  @supports not (aspect-ratio: 273/176) {
    width: 400px;
  }
`;

export const PostItemImageMain = styled.div`
  padding: 6px;
  background: #efe9e6;
  border-radius: 12px;
  box-shadow: 0px 1.2px 1.2px rgba(255, 255, 255, 0.45),
    inset 0px 0px 7.2px #d2bbbb;
  height: min-content;
  width: min-content;

  ${down("md")} {
    width: calc(100% - 64px);
    max-width: 231px;
    margin-left: 32px;
    margin-right: 32px;
    overflow: hidden;
    aspect-ratio: 273/176;
  }

  @supports not (aspect-ratio: 273/176) {
    div::before {
      float: left;
      padding-top: 56.25%;
      content: "";
    }

    div::after {
      display: block;
      content: "";
      clear: both;
    }
  }
`;

export const PostItemContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 32px 0px;
  cursor: pointer;

  :hover ${PostItemImage} {
    transform: scale(1.05);
  }

  ${down("md")} {
    flex-direction: column;
  }
`;

export const PostItemData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  margin: 0px 32px;
`;

export const PostItemDate = styled.span`
  ${LeadMStyle()}
  color: ${colors.text_second};

  ${down("md")} {
    width: 100%;
    margin-top: 2px;
  }
`;

export const PostItemCircleDivider = styled.div`
  width: 3px;
  height: 3px;
  margin-top: 2px;
  border-radius: 6px;
  overflow: hidden;
  background-color: ${colors.red._500};
  margin-right: 12px;

  ${down("md")} {
    display: none;
  }
`;

export const PostItemTitle = styled.h2`
  ${ListTitleLStyle()}
  ${down("md")} {
    margin-top: 16px;
    margin-bottom: 2px;
  }
`;

export const PostItemDescription = styled.span`
  font-family: "Barlow";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  color: ${colors.text_second};
`;

export const PostItemReadMoreContainer = styled.span`
  font-weight: 500;
  text-decoration: underline;
  :hover {
    text-decoration: none;
  }
  ${down("md")} {
    display: none;
  }
`;

export const PostItemAnimatedReadMore = styled.div`
  margin-top: 16px;
  display: none;

  ${down("md")} {
    display: block;
  }
`;

export const PostItemLink = styled.a`
  ${DefaultFocus()}
  text-decoration: none;
  color: black;
  display: inline-block;
  width: 100%;
`;

export const PostItemTagsDateContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 16px;

  ${down("md")} {
    margin-top: 0px;
  }
`;

export const PostItemTag = styled.span`
  ${LeadMStyle()}
  color: ${colors.red._500};
  text-decoration: none;
  margin-right: 12px;

  ${down("md")} {
    margin-right: 4px;
    color: ${colors.text_second};
  }
`;
