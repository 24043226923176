import styled from "styled-components";
import { down } from "utils/media";

export const PostsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 864px;
  margin: 0px auto;

  /* Hide top border of every child (execpt the first one) to avoid duplicated lines. */
  > *:not(:first-child) {
    border-top: none;
  }
`;

export const PostsListPostsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  ${down("md")} {
    margin-top: 0px;
  }
`;
