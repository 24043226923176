import Tags from "components/Tags/Tags";
import {
  TopBarBackground,
  TopBarContainer,
  TopBarHeader,
  TopBarHeaderContainer,
  TopBarImageContainer,
  TopBarImageWrapper,
  TopBarTagsContainer,
} from "components/TopBar/TopBar.components";
import Image from "next/image";
import React, { FunctionComponent } from "react";
import Model01 from "./model_01.webp";

type TopBarProps = {
  tags: string[];
  currentTag?: string;
};

const TopBar: FunctionComponent<TopBarProps> = ({ tags, currentTag }) => {
  return (
    <>
      <TopBarContainer>
        <TopBarHeaderContainer>
          <TopBarHeader>
            Stay in the know <b>with&nbsp;us</b>
          </TopBarHeader>
          <TopBarImageContainer
            transition={{
              duration: 2,
              repeatType: "mirror",
              ease: "easeInOut",
              repeat: Infinity,
            }}
            animate={{
              translateY: [0, 20],
            }}
          >
            <TopBarImageWrapper>
              <Image
                src={Model01}
                alt="Medical Logistics"
                layout="responsive"
                width={Model01.width}
                height={Model01.height}
              />
            </TopBarImageWrapper>
          </TopBarImageContainer>
        </TopBarHeaderContainer>
        <TopBarTagsContainer>
          <Tags tags={tags} currentTag={currentTag} isBig aligment="left" />
        </TopBarTagsContainer>
      </TopBarContainer>
      <TopBarBackground />
    </>
  );
};

export default TopBar;
